#root {
	height: 100vh;  
  margin: 0px;
  width: 100%; 
  background: white;

  display: flex;
  align-items: start;
}

@media all and (max-width: 1024px) {
}
@media all and (max-width: 768px) {
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 1024px) {
}

#App {
  background: white;
  position: relative;
  height: 802px;
  width: 1430px;
}